import './DataPrivacy.css';
import { linkTo_UnCryptMailto } from '../../Functions';


const DataPrivacy = ({ parentState, backCallerHandleCancelModalAreYouSure }) => {
    return (
        <>
            <div className="container-fluid text-start">
                <div className="row mb-3 mt-2">
                    <div className="col">
                        <p>
                            Die Kultusverwaltung Baden-Württemberg nimmt den Schutz Ihrer persönlichen Daten sehr ernst.
                            Aus diesem Grunde haben wir Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den
                            Datenschutz sowohl von uns als auch von unseren externen Dienstleistern beachtet werden.
                        </p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h3>Datenschutzerklärung</h3>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Allgemeine Information über die Erhebung personenbezogener Daten</h5>
                        <p>Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Website.
                            Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, Adresse,
                            E-Mail-Adressen, Nutzerverhalten. Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung
                            (DS-GVO) ist Herr Prof. Dr. Thomas Riecke-Baulecke (siehe unser Impressum).
                            Wenn Sie der Auffassung sind, dass wir datenschutzrechtliche Bestimmungen nicht einhalten, wenden Sie
                            sich bitte an unseren Datenschutzbeauftragten. Sie erreichen ihn
                            unter <a href={linkTo_UnCryptMailto('nbjmup;ebufotdivu{A{tm/lw/cxm/ef')}>datenschutz [at] zsl [dot] kv [dot] bwl [dot] de</a> oder
                            unserer Postadresse mit dem Zusatz "der Datenschutzbeauftragte". Wir werden uns dann umgehend mit Ihnen
                            in Verbindung setzen.</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <p>Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von Ihnen
                            mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von uns gespeichert,
                            um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die
                            Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche
                            Aufbewahrungspflichten bestehen. Falls wir für einzelne Funktionen unseres Angebots auf beauftragte
                            Dienstleister zurückgreifen oder Ihre Daten für werbliche Zwecke nutzen möchten, werden wir Sie
                            untenstehend im Detail über die jeweiligen Vorgänge informieren. Dabei nennen wir auch die
                            festgelegten Kriterien der Speicherdauer.</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Erhebung personenbezogener Daten bei Besuch unserer Website</h5>
                        <p>Bei der bloß informatorischen Nutzung der Website, also wenn Sie uns keine weiteren Informationen übermitteln, erheben wir keine personenbezogenen Daten. Durch die vorgelagerte Web Application Firewall (WAF) wird lediglich die IP-Adresse der BITBW erfasst und anonymisiert. Diese Daten sind ausschließlich technisch erforderlich, um die Funktionalität und Sicherheit der Website zu gewährleisten. Eine Speicherung von IP-Adressen von Benutzern erfolgt nicht.</p>
                        <ul>
                            <li>Anonymisierte IP-Adresse (nur IP-Adresse der BITBW)</li>
                            <li>Datum und Uhrzeit der Anfrage</li>
                            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
                            <li>Inhalt der Anforderung (konkrete Seite)</li>
                            <li>Zugriffsstatus/HTTP-Statuscode</li>
                            <li>Jeweils übertragene Datenmenge</li>
                            <li>Website, von der die Anforderung kommt</li>
                            <li>Browser</li>
                            <li>Betriebssystem und dessen Oberfläche</li>
                            <li>Sprache und Version der Browsersoftware</li>
                        </ul>
                        <p>Rechtsgrundlage für die Erhebung dieser Daten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO, basierend auf dem berechtigten Interesse, die Stabilität und Sicherheit der Website zu gewährleisten.</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Einsatz von Cookies</h5>
                        <p>Zusätzlich zu den zuvor genannten Daten können bei Ihrer Nutzung unserer Website Cookies auf Ihrem
                            Rechner gespeichert werden. Bei Cookies handelt es sich um kleine Textdateien, die auf Ihrer Festplatte dem von
                            Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, die den Cookie
                            setzt (hier durch uns), bestimmte Informationen zufließen. Cookies können keine Programme ausführen
                            oder Viren auf Ihren Computer übertragen. Sie dienen dazu, das Internetangebot insgesamt nutzerfreundlicher
                            und effektiver zu machen. </p>
                        {/* <ul><li>Transiente Cookies</li>
                            <li>Persistente Cookies</li>
                        </ul>
                        <p>Transistente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere
                            die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen
                            Ihres Browsers der gemeinsamen Sitzung zuordnen lassen. Dadurch kann Ihr Rechner wiedererkannt werden,
                            wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie sich ausloggen
                            oder den Browser schließen. Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht,
                            die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den Sicherheitseinstellungen Ihres
                            Browsers jederzeit löschen. Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren
                            und z. B. die Annahme von Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass
                            Sie eventuell nicht alle Funktionen dieser Website nutzen können.</p>
                        <p>Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie über einen Account bei
                            uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut einloggen.</p>
                        <p>Die genutzten Flash-Cookies werden nicht durch Ihren Browser erfasst, sondern durch Ihr Flash-Plug-in.
                            Weiterhin nutzen wir HTML5 storage objects, die auf Ihrem Endgerät abgelegt werden. Diese Objekte
                            speichern die erforderlichen Daten unabhängig von Ihrem verwendeten Browser und haben kein automatisches
                            Ablaufdatum. Wenn Sie keine Verarbeitung der Flash-Cookies wünschen, müssen Sie ein entsprechendes Add-On
                            installieren, z.B. "Better Privacy" für Mozilla Firefox (https://addons.mozilla.org/de/firefox/addon/betterprivacy/)
                            oder das Adobe-Flash-Killer-Cookie für Google Chrome. Die Nutzung von HTML5 storage objects können Sie verhindern,
                            indem Sie in Ihrem Browser den privaten Modus einsetzen. Zudem empfehlen wir, regelmäßig Ihre Cookies und
                            den Browser-Verlauf manuell zu löschen.</p> */}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Zweck der Datenerfassung</h5>
                        <p>Unsere Anwendung/Webseite speichert und
                            verarbeitet Daten ausschließlich lokal auf dem Gerät des Nutzers.
                            Es erfolgt keinerlei Sammlung persönlicher Daten, keine Datenübertragung an den
                            Server oder Dritte, und keine Nutzung der Daten für Werbe- oder Tracking-Zwecke.</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Art der gesammelten Daten</h5>
                        <p>Im Falle der lokalen Speicherung durch den Nutzer,
                            werden ausschließlich Konfigurationseinstellungen und Nutzereingaben persistiert.
                            Diese Daten werden ausschließlich lokal auf dem Gerät des Nutzers
                            gespeichert und verlassen dieses nicht.</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Speicherung und Sicherheit</h5>
                        <p>Die gesammelten Daten werden auf dem Gerät des Nutzers gespeichert.
                            Im Rahmen der dienstlichen Nutzung des Systems ergreifen alle Beteiligten die
                            notwendigen technischen und organisatorischen Maßnahmen,
                            um die Sicherheit und den Schutz der lokal gespeicherten Daten zu gewährleisten.</p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col">
                        <h5>Ihre Rechte</h5>
                        <p>Sie haben gegenüber uns folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>
                        <ul>
                            <li>Recht auf Auskunft</li>
                            <li>Recht auf Berichtigung oder Löschung,</li>
                            <li>Recht auf Einschränkung der Verarbeitung,</li>
                            <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
                            <li>Recht auf Datenübertragbarkeit.</li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <p>Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung
                            Ihrer personenbezogenen Daten durch uns zu beschweren Wir weisen darauf hin, dass die Datenübertragung
                            im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
                            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der Nutzung von
                            im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
                            nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
                            widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle
                            der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
                    </div>
                </div>


                {/* <div className="row">
                    <div className="col-6">
                        <ol>
                            <li>Verantwortlicher für die Datenverarbeitung<br />
                                Name/Unternehmen: <span className="fw-bold">[Ihr Name oder der Name Ihres Unternehmens]</span><br />
                                Adresse: <span className="fw-bold">[Ihre Adresse]</span><br />
                                E-Mail: <span className="fw-bold">[Ihre E-Mail-Adresse]</span>
                            </li>
                            <li>Zweck der Datenerfassung<br />
                                Unsere Anwendung/Webseite <span className="fw-bold">[UBB]</span> speichert und
                                verarbeitet Daten ausschließlich lokal auf dem Gerät des Nutzers.
                                Es erfolgt keinerlei Sammlung persönlicher Daten, keine Datenübertragung an den
                                Server oder Dritte, und keine Nutzung der Daten für Werbe- oder Tracking-Zwecke.
                            </li>
                            <li>Art der gesammelten Daten<br />
                                Die Anwendung/Webseite sammelt folgende Daten:
                                <span className="fw-bold">[Im Falle der lokalen Speicherung durch den Nutzer,
                                    werden ausschließlich Konfigurationseinstellungen und Nutzereingaben persistiert.]</span>
                                Diese Daten werden ausschließlich lokal auf dem Gerät des Nutzers
                                gespeichert und verlassen dieses nicht.
                            </li>
                        </ol>
                    </div>
                    <div className="col-6">
                        <ol start="4">
                            <li>Speicherung und Sicherheit<br />
                                Die gesammelten Daten werden auf dem Gerät des Nutzers gespeichert.
                                Im Rahmen der dienstlichen Nutzung des Systems ergreifen alle Beteiligten die
                                notwendigen technischen und organisatorischen Maßnahmen,
                                um die Sicherheit und den Schutz der lokal gespeicherten Daten zu gewährleisten.
                            </li>
                            <li>Rechte der Nutzer<br />
                                Als Nutzer unserer Anwendung/Webseite haben Sie das Recht,
                                Auskunft über die von Ihnen gespeicherten Daten zu verlangen.
                                Sie haben auch das Recht, die Berichtigung, Löschung oder Einschränkung
                                der Verarbeitung dieser Daten zu verlangen.
                            </li>
                            <li>Kontaktinformationen<br />
                                Bei Fragen zur Datenerfassung, -verarbeitung oder -nutzung durch
                                unsere Anwendung/Webseite, für Auskünfte, Berichtigung, Sperrung
                                oder Löschung von Daten kontaktieren Sie uns bitte unter
                                <span className="fw-bold">[Ihre Kontaktinformationen]</span>.
                            </li>
                        </ol>
                    </div>
                    <div className="col-12 text-end">
                        <p>Diese Datenschutzerklärung wurde zuletzt aktualisiert am <span className="fw-bold">[14.03.2024]</span>.</p>
                    </div>
                </div> */}

                <p className="d-inline-flex gap-1">
                    <a className="btn border" data-bs-toggle="collapse" href="#collapseHinweise" role="button" aria-expanded="false" aria-controls="collapseHinweise">
                        <span className="fw-bold">Weitere Hinweise</span>
                    </a>
                </p>
                <div className="collapse" id="collapseHinweise">
                    <div className="card card-body">
                        Die Speicherung und Verarbeitung personenbezogener Daten ist nur unter
                        Beachtung und Umsetzung geeigneter technischer und organisatorischer
                        Maßnahmen (TOM) im Sinne des Art. 32 EU-DSGVO zulässig.
                    </div>
                </div>
            </div>
        </>
    )
}

export default DataPrivacy;