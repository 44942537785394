import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import "bootstrap-icons/font/bootstrap-icons.css";
import './NavbarTop.css';

const packageJson = require('../../../package.json');

const NavbarTop = ({
    parentState,
    backCallerGoModal,
    backCallerHandleExportRequestAll,
    backCallerHandlePrintRequestAll,
    backCallerHandleSave,
    backCallerHandleFileUploadChange,
    backCallerHandleCallbackChangePWith,
    backCallerHandleExport,
}) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
        // console.log("expanded: " + expanded);
    };

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    // useEffect(() => {
    //     console.log(parentState);
    //     console.log(packageJson);
    // }, []);

    return (
        // bg="light"
        <Navbar expanded={expanded} onToggle={(e) => handleToggle()} expand="xl" fixed="top" className="no-print nbBackGround">
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <img
                        id="headerImageLion"
                        src={`${process.env.PUBLIC_URL}/bw.svg`}
                        alt="Baden-Württemberg"
                        title="Baden-Württemberg"
                    />

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="ubbnavbar" onClick={(e) => handleToggle()} />
                {/* <Navbar.Collapse id="ubbnavbar"> */}

                <Navbar.Collapse id="ubbnavbar" className={expanded ? 'nav-collapsed' : ''}>

                    <Nav className="me-auto mb-2 mb-md-0">
                        <NavDropdown title="Bearbeitungsstand" id="dd_1">
                            <NavDropdown.Item as="label" htmlFor="inputFileUpload" style={{ cursor: 'pointer' }}>
                                Laden
                                <input
                                    id="inputFileUpload"
                                    type="file"
                                    style={{ display: 'none' }}
                                    //   onChange={e => this.handleFileUploadChange(e, this)}
                                    onChange={(e) => backCallerHandleFileUploadChange(e)}
                                />
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#" onClick={(e) => { backCallerHandleSave(); handleNavItemClick(); }}>Speichern</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={(e) => { backCallerHandlePrintRequestAll(); handleNavItemClick(); }}>Drucken</NavDropdown.Item>
                            <NavDropdown.Divider />
                            {/* <NavDropdown.Item href="#" onClick={(e) => { backCallerHandleExportRequestAll(); handleNavItemClick(); }}>Exportieren</NavDropdown.Item> */}
                            <NavDropdown.Item href="#" onClick={(e) => { backCallerHandleExport(); handleNavItemClick(); }}>Exportieren</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#" onClick={(e) => { backCallerGoModal('reset'); handleNavItemClick(); }}>Zurücksetzen</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/meta" onClick={handleNavItemClick}>Metadaten</Nav.Link>
                        <Nav.Link as={Link} to="/notes" onClick={handleNavItemClick}>Notizen</Nav.Link>
                        <Nav.Link as={Link} to="/donut" onClick={handleNavItemClick}>Kreisdarstellung</Nav.Link>
                        <Nav.Link as={Link} to="/table" onClick={handleNavItemClick}>Tabellarische Ansicht</Nav.Link>
                        <Nav.Link as={Link} to="/data_privacy" onClick={handleNavItemClick}>Datenschutz</Nav.Link>
                        <Nav.Link as={Link} to="/imprint" onClick={handleNavItemClick}>Impressum</Nav.Link>
                    </Nav>

                    <Nav className="ms-auto d-flex flex-row align-items-center">

                        <Nav.Link href="#" className="pe-0" onClick={(e) => { backCallerHandleCallbackChangePWith(0); handleNavItemClick(); }}>
                            <i className={`bi bi-textarea-t ${(parentState.pWith == 0) ? 'pWithSelected' : ''}`}
                            />
                        </Nav.Link>

                        <Nav.Link href="#" className="pe-0" onClick={(e) => { backCallerHandleCallbackChangePWith(2); handleNavItemClick(); }}>
                            <i className={`bi bi-pencil-square ${(parentState.pWith == 2) ? 'pWithSelected' : ''}`}
                            />
                        </Nav.Link>

                        {/* <Nav.Link href="#" className="pe-2" onClick={(e) => { backCallerHandleCallbackChangePWith(2); handleNavItemClick(); }}>
                            <i class="bi bi-info"></i>
                        </Nav.Link> */}

                    </Nav>

                </Navbar.Collapse>
                {/* {!expanded && (
                    <div className="collapsed-text">
                        Collapsed Text
                    </div>
                )} */}
            </Container>
        </Navbar>
    );
};

export default NavbarTop;



{/* <div className="navbar-nav switch-toggle switch-3 switch-candy d-flex">
                <input id="text" name="rViewState" type="radio"
                  checked={this.state.pWith === 0}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="text" onClick={(e) => this.viewChanged(0)}>Text</label>
                <input id="both" name="rViewState" type="radio"
                  checked={this.state.pWith === 1}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="both" onClick={(e) => this.viewChanged(1)}>Beides</label>
                <input id="pen" name="rViewState" type="radio"
                  checked={this.state.pWith === 2}
                  onChange={this.handleRadioChange}
                />
                <label htmlFor="pen" onClick={(e) => this.viewChanged(2)}>Stift</label>
              </div> */}