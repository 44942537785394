import './PageNotFound.css';

const PageNotFound = ({ parentState, backCallerHandleCancelModalAreYouSure }) => {

    return (
        <>
            <div className="container-fluid text-start">
                <h5 className="text-danger">Die von Ihnen gew&uuml;nschte Seite konnte leider nicht gefunden werden.</h5>
                <div className="row">
                    <div className="col-12 text-start">
                        <h6>Pr&uuml;fen Sie Ihre Eingabe.</h6>
                        <p> Falls der Fehler weiterhin besteht, melden Sie uns den Fehler bitte und
                            geben Sie folgende Information mit an.
                        </p>
                        <p><code>[{new Date().toLocaleString()}, {window.location.href}]</code></p>
                        <h4>Vielen lieben Dank für Ihre Mithilfe!</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;